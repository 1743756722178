import { Component, Input, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faFileCsv} from '@fortawesome/free-solid-svg-icons';
import { faFile} from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment, AttachmentDTO, CommentDto } from 'src/app/web-api-client';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})

export class CommentItemComponent implements OnInit {
  @Input() comment?: CommentDto
  @Input() discussionId?: string
  attachments : Attachment[] = [];
  pseudo: string = "";
  faFilePdf = faFilePdf;
  faFileExcel = faFileExcel;
  faFileCsv = faFileCsv;
  faFile = faFile;
  filesPath: string[] = [];
  @Input() userId: string;
  commentClass: string = "comment-item"

  constructor( private sanitizer: DomSanitizer,private commentService:CommentService, public documentService: DocumentService) { }
  
  ngOnInit(): void {
    this.attachments = this.comment?.attachments;
    this.getPseudo();
    this.changeClass();
    
  }

  getPseudo(){
    this.pseudo = this.comment.firstName[0];
  }

  convertFileType(type:string){
    switch(type){
      case ".pdf" :
        return this.faFilePdf;
      case ".xsl":
        return this.faFileExcel;
      case ".csv":
        return this.faFileCsv;
      default :
        return this.faFile;
      }
  }

  sanitize(url: string) {
    // return safe url
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  changeClass(){
    if(this.userId == this.comment?.userId){
      this.commentClass="my-comment comment-item";
    }
  }

  // method to download the file
async downloadCommentFile(file: Attachment) {
  // download the file from the server
  let download = "data:application/octet-stream;base64," + await this.commentService.downloadFile(this.comment?.id,this.discussionId, file.id);
  // download the file
  var a = document.createElement('a');
  a.setAttribute('href', download);
  a.setAttribute('download', file.name);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
}
