
<div class="pseudo">{{ pseudo }}</div>
<div class={{commentClass}}>
  <p class="comment-info"><strong>{{ comment?.firstName }} {{ comment?.lastName }}, {{ comment?.messageSent | date }}</strong></p>
  <div class="comment" [innerHTML]="comment?.content"></div>
  <div *ngIf="comment?.attachments" class="attachments">
    <div class="attachment" *ngFor="let attachment of attachments; let i = index" (click)="downloadCommentFile(attachment)" >
            <a class="{{attachment?.fileType}}" >
                <span class="file-type">
                    <fa-icon [icon]="convertFileType(attachment?.fileType)"></fa-icon>
                </span>{{ documentService.sanitizeFileName(attachment?.name)}}
            </a>
    </div>
    
  </div>
</div>
