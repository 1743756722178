import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';
import { faTh,faBars } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-illustration-input-list-page',
  templateUrl: './illustration-input-list-page.component.html',
  styleUrls: ['./illustration-input-list-page.component.scss']
})
export class IllustrationInputListPageComponent implements OnInit {
  
  isTableView:boolean = false;
  headers:string[]=["Client","Network Member","Country","Status"]
  // hold the process id
  process = "illustration-input"
  isTableViewStoredValueKey:string;
  // determine if the user is a collaborator
  isCollaborator: boolean;
  statusFilter: number;
  faTh = faTh;
  faBars = faBars;

  // constructor
  constructor(private userService : UserService,
    private titleService: Title) { }

  // oninit method
  async ngOnInit() {

    // determine if the user is a collaborator
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Illustration-input | Insurope");
    this.isTableViewStoredValueKey=this.titleService.getTitle()+'_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;

    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
     
    this.changeIsTableViewValue(storedValue);
  }
  
    changeIsTableViewValue(value){
    if(value==null){
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    }else{
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}