import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, last, map } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients.service';
import { AccountDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-clients-search',
  templateUrl: './clients-search.component.html',
  styleUrls: ['./clients-search.component.scss']
})
export class ClientsSearchComponent implements OnInit {

  clickedOnClient: boolean = false;
  @Input() isOnClientView: boolean;

  @Output() clientEmitter = new EventEmitter<AccountDto>();

  searchResult: AccountDto[];
  searchingClient: boolean = false;

  @ViewChild('searchClientField', { static: true }) searchInputField: ElementRef;

  constructor(private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    if (localStorage.getItem('clientName') || localStorage.getItem('clientId')) {
      this.searchInputField.nativeElement.value = localStorage.getItem('clientName');
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: { clientName: localStorage.getItem('clientName') },
          queryParamsHandling: 'merge',
        });
    }

    if (this.isOnClientView) {
      fromEvent(this.searchInputField.nativeElement, 'keyup').pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater than 1
        filter(res => res.length >= 3),
        // Time in milliseconds between key events
        debounceTime(50),
        // If previous query is diffent from current   
        distinctUntilChanged()
        // subscription for response
      ).subscribe((text: string) => {
        this.clickedOnClient = false;
        this.fetchClients(text);
      });
    }
    else {
      fromEvent(this.searchInputField.nativeElement, 'keyup').pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater than 1
        filter(res => res.length >= 4),
        // Time in milliseconds between key events
        debounceTime(50),
        // If previous query is diffent from current   
        distinctUntilChanged()
        // subscription for response
      ).subscribe((text: string) => {
        this.clickedOnClient = false;
        this.loadClientByName(text);
      });

      fromEvent(this.searchInputField.nativeElement, 'keydown').pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        filter(res => res.length < 1),
      ).subscribe((text: string) => {
        this.clearSearch();
      });
    }
  }

  async fetchClients(term: string) {
    if (term === '') {
      return this.searchResult = [];
    }
    this.searchingClient = true;
    this.searchResult = await this.clientsService.getClientsByName(term, 0, 10);
    this.searchingClient = false;
  }

  async loadClientByName(accountName) {
    this.clickedOnClient = true;

    localStorage.setItem('clientName', accountName);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { clientName: accountName },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.searchResult = [];
    this.searchInputField.nativeElement.value = accountName;
  }

  async loadClient(client: AccountDto) {
    this.clickedOnClient = true;
    this.searchInputField.nativeElement.value = client.accountName;
    //localStorage.setItem('clientName', client.accountName);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { clientId: client.id },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.searchResult = [];
    this.clientEmitter.emit(client);
    this.searchInputField.nativeElement.value = client.accountName;
  }

  async clearSearch() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('clientName');
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { clientName: null,clientId:null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.clientEmitter.emit(null);
    this.searchResult = [];
    this.searchInputField.nativeElement.value = "";
  }
}
