<section class="sub-header">
    <!-- Show the back btn -->
    <button class="btn btn-back" (click)="location.back()">Back</button>
    <!-- Show the title -->
    <h2>Upload a Manual Illustration Request</h2>
  </section>
  
  <section class="card mb-3 upload-box">
    <div class="card-body">
      <div class="line-of-2">
        <div class="d-flex flex-column justify-content-center align-items-center upload">
          <p class="font-weight-bold mb-3 text-center">
            Upload your illustration files
          </p>
          <img src="assets/upload-icon.png" alt="upload" class="mb-3" />
  
          <!-- Show the upload button component -->
          <app-file-upload-button class="mb-3" (fileUploaded)="fileUploaded($event)" [acceptMultipleFiles]="true">
          </app-file-upload-button>
  
          <!-- Show the uploaded files -->
          <div class="mb-3" *ngFor="let file of uploadedFiles; let i = index">
            {{ file?.fileName }}
            <button type="button" class="close ml-1" aria-label="Close" (click)="removeFile(i)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
  
        <div>
            <form [formGroup]="illustrationForm" class="illustration-form">
                <div class="form-group required" id="searchClientName">
                  <!-- <details>
                    <summary><label for="ClientName">Client Name</label></summary>
                    <p><small>Please type part of the client's headquarters/parent name to search for the client. If nothing is found, please type the full HQ client's name (not the subsidiary name here).</small></p>
                  </details> -->
                  <label for="ClientName">Client Name</label>
                  <section class="d-flex filter-box custom-filter">
                      <input type="text" placeholder="Search for a client name" required class="form-control" #searchClientField formControlName="ClientName">                    
                      <ul *ngIf="!searchingClient">
                        <li *ngIf="!selectedClient && illustrationForm.controls.ClientName.value != '' && !newClient && !searchingClient" (click)="selectClient(null)"><p>The client I search for is not found?</p></li>
                        <li *ngFor="let client of filteredClients" (click)="selectClient(client)">
                          <p> {{ client.accountName }}</p>
                        </li>
                      </ul>
                      <!-- <small *ngIf="searchingClient" class="form-text text-muted">searching...</small> -->
                      <div *ngIf="searchingClient" class="mt-2 searching-spinner">
                        <div class="spinner-border spinner-border-sm mr-1" role="status">
                        </div>
                        <span>Searching...</span>
                      </div>
                  </section>
                  <p><small>Please type part of the client's headquarters/parent name to search for the client. If nothing is found, please type the full HQ client's name (not the subsidiary name here).</small></p>
                </div>
                <div class="form-group required">
                    <label>Type of business</label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="InsuropeTypeOfBusiness" required name="InsuropeTypeOfBusiness" id="newInsuropeTypeOfBusiness" value="New" checked>
                        <label class="form-check-label" for="newInsuropeTypeOfBusiness">New</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="InsuropeTypeOfBusiness" required name="InsuropeTypeOfBusiness" id="existingInsuropeTypeOfBusiness" value="Existing">
                        <label class="form-check-label" for="existingInsuropeTypeOfBusiness">Existing</label>
                      </div>
                </div>
                <div class="form-group required" id="searchCountryName">
                  <label for="Country">Country</label>
                  <section class="d-flex filter-box mb-3 custom-filter">
                    <input type="text" class="form-control" id="Country" required #searchCountryField formControlName="Country">
                    <ul *ngIf="selectedCountry || filteredCountries.length > 0">
                      <li *ngFor="let cty of filteredCountries" (click)="selectCountry(cty)">
                        <p> {{ cty.name }}</p>
                      </li>
                    </ul>
                    <small *ngIf="searchingCountry" class="form-text text-muted">searching...</small>
                  </section>
                </div>
                <div class="form-group required">
                    <label for="AdditionalComment">Comment</label>
                    <textarea class="form-control" id="AdditionalComment" required rows="6" formControlName="AdditionalComment"></textarea>
                </div>
            </form>
            <button (click)="save()" [disabled]="illustrationForm.invalid || !selectedCountry" class="btn btn-primary d-inline" type="button">
              <span *ngIf="sendingRequest" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              Send
            </button>
            <app-message-box *ngIf="showWarningMessage" title="Warning" message="Send without attachments?" type="warning" btnOkText="Yes" btnCancelText="No" (acceptEmitter)="createIllustration()" (declineEmitter)="showWarningMessage = false;"></app-message-box>
        </div>
      </div>
    </div>
  </section>