<section *ngIf="client" class="client-tabs mb-3">
  <ul>
    <li>
      <button (click)="changeView('info')" class="tab">Info</button>
    </li>
    <li>
      <button (click)="changeView('contacts'); loadContacts()" class="tab">Contacts</button>
    </li>
    <li>
      <button (click)="changeView('contractualDocuments'); loadContractualDocuments()" class="tab">Contractual documents</button>
    </li>
    <li>
      <button (click)="changeView('attachments'); loadAttachments()" class="tab">Attachments</button>
    </li>
    <li>
      <button (click)="changeView('expansionPlans'); loadExpansionPlans()" class="tab">Expansion plan</button>
    </li>
  </ul>

  <!--<div class="d-flex flex-column justify-content-end">
    <button class="btn btn-primary btn-action mb-3 align-self-end" [routerLink]="'/illustration-request'">
      Request illustration
    </button>
  </div>-->
</section>

<div *ngIf="client && viewToDisplay == 'info'" class="mb-3 card card-body">

  <div class="line-of-2">
    <div>
      <div *ngIf="client.type" class="mb-3">
        <h3>Type</h3>
        <span>{{ client.type }}</span>
      </div>
      
      <div *ngIf="client.accountX.systems" class="mb-3">
        <h3>Pooling System</h3>
        <span>{{ client.accountX.systems }}</span>
      </div>
      
      <div *ngIf="client.accountX.dividend" class="mb-3">
        <h3>Dividend paid</h3>
        <span>{{ client.accountX.dividend }}</span>
      </div>

      <div *ngIf="client.currencyCode" class="mb-3">
        <h3>Currency</h3>
        <span>{{ client.currencyCode }}</span>
      </div>

      <div *ngIf="client.accountX.pooldate" class="mb-3">
          <h3>Pool starting date</h3>
          <span>{{ client.accountX.pooldate | date }}</span>
      </div>
      
      <div *ngIf="client.accountX.poolComments" class="mb-3">
          <h3>Comments</h3>
          <span>{{ client.accountX.poolComments }}</span>
      </div>
    </div>

    <div>
      <div class="mb-3">
        <h3>Result</h3>
        <span>{{ client.accountX.result | number }} {{ client.currencyCode }}</span>
      </div>

      <div class="mb-3">
        <h3>Carry Forward Result</h3>
        <span>{{ client.accountX.carry_forward_result | number }} {{ client.currencyCode }}</span>
      </div>

      <div *ngIf="client.accountX.last_Account" class="mb-3">
        <h3>Last account</h3>
        <span>{{ client.accountX.last_Account }}</span>
      </div>

      <div *ngIf="client.accountX.lives" class="mb-3">
        <h3>Total pooled lives</h3>
        <span>{{ client.accountX.lives }}</span>
      </div>

      <div class="mb-3">
        <h3>Total pooled premium - common currency</h3>
        <span>{{ client.accountX.premium | number }} {{ client.currencyCode }}</span>
      </div>
    </div>
    
    <div>  
      <div class="mb-3">
        <h3 class="mb-3">Important files
          <div *ngIf="loadingImportantFiles" class="spinner-border spinner-border-sm mr-1" role="status"></div>
        </h3>
        <div class="card">
          <div class="card-body">
            <p><a role="button" class="text-info font-weight-bold" (click)="downloadLatestAccountingExcel()">Latest accounting excel</a></p>
            <p *ngIf="latestAnnualReportId"><a role="button" class="text-info font-weight-bold" (click)="downloadAttachment(latestAnnualReportId)">Latest annual report</a></p>
            <p *ngIf="latestCompositionReportId"><a role="button" class="text-info font-weight-bold" (click)="downloadAttachment(latestCompositionReportId)">Latest composition report</a></p>
            <p *ngIf="latestDividendPaymentLetterId"><a role="button" class="text-info font-weight-bold" (click)="downloadAttachment(latestDividendPaymentLetterId)">Latest dividend payment letter</a></p>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <h3>Current {{ client.accountX.currentPooledCountries }} of Pooled Countries (EP)</h3>
      </div>
      
      <div class="mb-3">
        <h3>Current {{ client.accountX.currentPRCountries }} of Non-Pooled Countries (EP)</h3>
      </div>
    </div>

  </div>
</div>

<div *ngIf="viewToDisplay == 'contacts'" class="mb-3 card card-body">

  <div *ngIf="loadingContacts" class="spinner-border spinner-border-sm mb-3" role="status"></div>

  <div class="row">
    <div class="col-2">
      <div class="mb-3">
        <h2>Acc manager</h2>
      </div>
      <div class="mb-3">
        <h3>Name</h3>
        <span>{{ client.accountManager.userInfo.userName }}</span>
      </div>
      <div class="mb-3">
        <h3>Email</h3>
        <span><a href="mailto:{{client.accountManager.userInfo.email}}">{{ client.accountManager.userInfo.email }}</a></span>
      </div>
      <div class="mb-3" *ngIf="client.accountManager.userInfo.phone">
        <h3>Phone nr</h3>
        <span>{{ client.accountManager.userInfo.phone }}</span>
      </div>
      <div class="mb-3" *ngIf="client.accountManager.userInfo.mobile">
        <h3>Mobile nr</h3>
        <span>{{ client.accountManager.userInfo.mobile }}</span>
      </div>
      <div class="mb-3" *ngIf="client.accountManager.userInfo.region">
        <h3>Region</h3>
        <span>{{ client.accountManager.userInfo.region }}</span>
      </div>
      <div class="mb-3" *ngIf="client.accountManager.userInfo.address.country">
        <h3>Country</h3>
        <span>{{ client.accountManager.userInfo.address.country }}</span>
      </div>
    </div>
    <div class="col-2">
      <div class="mb-3">
        <h2>Support</h2>
      </div>
      <div class="mb-3">
        <h3>Name</h3>
        <span>{{ client.divisionalManager.userInfo.userName }}</span>
      </div>
      <div class="mb-3">
        <h3>Email</h3>
        <span><a href="mailto:{{ client.divisionalManager.userInfo.email }}">{{ client.divisionalManager.userInfo.email }}</a></span>
      </div>
      <div class="mb-3" *ngIf="client.divisionalManager.userInfo.phone">
        <h3>Phone nr</h3>
        <span>{{ client.divisionalManager.userInfo.phone }}</span>
      </div>
      <div class="mb-3" *ngIf="client.divisionalManager.userInfo.mobile">
        <h3>Mobile nr</h3>
        <span>{{ client.divisionalManager.userInfo.mobile }}</span>
      </div>
      <div class="mb-3" *ngIf="client.divisionalManager.userInfo.region">
        <h3>Region</h3>
        <span>{{ client.divisionalManager.userInfo.region }}</span>
      </div>
      <div class="mb-3" *ngIf="client.divisionalManager.userInfo.address.country">
        <h3>Country</h3>
        <span>{{ client.divisionalManager.userInfo.address.country }}</span>
      </div>
    </div>
    <div class="col-2" *ngIf="technicalContact">
      <div class="mb-3">
        <h2>Technical</h2>
      </div>
      <div class="mb-3">
        <h3>Name</h3>
        <span>{{ technicalContact.name }}</span>
      </div>
      <div class="mb-3" *ngIf="technicalContact.email">
        <h3>Email</h3>
        <span><a href="mailto:{{ technicalContact.email }}">{{ technicalContact.email }}</a></span>
      </div>
      <div class="mb-3" *ngIf="technicalContact.phone">
        <h3>Phone nr</h3>
        <span>{{ technicalContact.phone }}</span>
      </div>
    </div>
    <div class="col-2" *ngIf="companyContact">
      <div class="mb-3">
        <h2>Company</h2>
      </div>
      <div class="mb-3">
        <h3>Name</h3>
        <span>{{ companyContact.name }}</span>
      </div>
      <div class="mb-3">
        <h3>Email</h3>
        <span><a href="mailto:{{ companyContact.email }}">{{ companyContact.email }}</a></span>
      </div>
      <div class="mb-3" *ngIf="companyContact.workPhone">
        <h3>Phone nr</h3>
        <span>{{ companyContact.workPhone }}</span>
      </div>
    </div>
    <div class="col-2" *ngIf="consultantContact">
      <div class="mb-3">
        <h2>Consultant</h2>
      </div>
      <div class="mb-3">
        <h3>Name</h3>
        <span>{{ consultantContact.name }}</span>
      </div>
      <div class="mb-3">
        <h3>Email</h3>
        <span><a href="mailto:{{ consultantContact.email }}">{{ consultantContact.email }}</a></span>
      </div>
      <div class="mb-3" *ngIf="consultantContact.workPhone">
        <h3>Phone nr</h3>
        <span>{{ consultantContact.workPhone }}</span>
      </div>
    </div>
  </div>

</div>

<div *ngIf="viewToDisplay == 'contractualDocuments'" class="mb-3 card card-body">

  <div class="d-flex justify-content-start">
    <h1>Contractual documents</h1>
    <div *ngIf="loadingContractualDocuments" class="spinner-border spinner-border-sm ml-3" role="status"></div>
  </div>

  <table class="table table-borderless" id="excel-table">
      
    <thead>
      <tr class="table-head">
        <th style="width: 40%;">Attachment</th>
        <th>User</th>
        <th>Modified Date</th>
        <th>Size</th>
        <th>Download</th>
      </tr>
    </thead>

    <p class="mt-3" *ngIf="contractualDocuments?.items.length == 0 && !loadingContractualDocuments">There are no contractual documents.</p>

    <tbody *ngIf="contractualDocuments?.items.length > 0">
      <tr *ngFor="let contractualDocument of contractualDocuments?.items; let i = index">
        <td data-label="Attachment">{{ contractualDocument.description }}</td>
        <td data-label="User">{{ contractualDocument.createUser.userName }}</td>
        <td data-label="Modified Date">{{ contractualDocument.modifyDate | date:'d MMM y' }}</td>
        <td data-label="Size">{{ contractualDocumentsSizes[i] }}</td>
        <td data-label="Download"><button type="button" class="btn btn-link text-dark" (click)="downloadAttachment(contractualDocument.id)">
          <fa-icon [icon]="faDownload" size="lg"></fa-icon>
        </button></td>
    </tr>
    </tbody>
    
  </table>

  <div class="d-flex justify-content-center mt-3" *ngIf="contractualDocuments?.items.length > 0 && contractualDocuments?.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{'disabled': !contractualDocuments?.hasPreviousPage}">
        <a class="page-link" role="button" (click)="loadContractualDocuments(contractualDocumentsPageNumber - 1)">Previous</a>
      </li>
      <li class="page-item" [ngClass]="{'active': contractualDocumentsPageNumber == (i + 1)}" *ngFor="let item of counter(contractualDocuments?.totalPages); let i = index">
        <a class="page-link" role="button" (click)="loadContractualDocuments(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [ngClass]="{'disabled': !contractualDocuments?.hasNextPage}">
        <a class="page-link" role="button" (click)="loadContractualDocuments(contractualDocumentsPageNumber + 1)">Next</a>
      </li>
    </ul>
  </div>

</div>

<div *ngIf="viewToDisplay == 'attachments'" class="mb-3 card card-body">

  <div class="d-flex justify-content-start">
    <h1>Attachments</h1>
    <div *ngIf="loadingAttachments" class="spinner-border spinner-border-sm ml-3" role="status"></div>
  </div>

  <table class="table table-borderless" id="excel-table">
      
    <thead>
      <tr class="table-head">
        <th style="width: 40%;">Description</th>
        <th>User</th>
        <th>Attached Date</th>
        <th>Size</th>
        <th>Download</th>
      </tr>
    </thead>

    <p class="mt-3" *ngIf="attachments?.items.length == 0 && !loadingAttachments">There are no attachments.</p>

    <tbody *ngIf="attachments?.items.length > 0">
      <tr *ngFor="let attachment of attachments?.items; let i = index">
        <td data-label="Attachment">{{ attachment.description }}</td>
        <td data-label="User">{{ attachment.createUser.userName }}</td>
        <td data-label="Modified Date">{{ attachment.attachDate | date:'d MMM y' }}</td>
        <td data-label="Size">{{ attachmentsSizes[i] }}</td>
        <td data-label="Download"><button type="button" class="btn btn-link text-dark" (click)="downloadAttachment(attachment.id)">
          <fa-icon [icon]="faDownload" size="lg"></fa-icon>
        </button></td>
    </tr>
    </tbody>
    
  </table>

  <div class="d-flex justify-content-center mt-3" *ngIf="attachments?.items.length > 0 && attachments?.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{'disabled': !attachments?.hasPreviousPage}">
        <a class="page-link" role="button" (click)="loadAttachments(attachmentsPageNumber - 1)">Previous</a>
      </li>
      <li class="page-item" [ngClass]="{'active': attachmentsPageNumber == (i + 1)}" *ngFor="let item of counter(attachments?.totalPages); let i = index">
        <a class="page-link" role="button" (click)="loadAttachments(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [ngClass]="{'disabled': !attachments?.hasNextPage}">
        <a class="page-link" role="button" (click)="loadAttachments(attachmentsPageNumber + 1)">Next</a>
      </li>
    </ul>
  </div>

</div>
<div *ngIf="viewToDisplay == 'expansionPlans'" class="d-flex justify-content-start">
  <h1>Expansion plans</h1>
  <div *ngIf="loadingExpansionPlans" class="spinner-border spinner-border-sm ml-3" role="status"></div>
  <button type="button" *ngIf="expansionPlans?.items.length > 0" [disabled]="exportingExpansionPlans" class="btn btn-primary mb-3 ms-auto" (click)="exportexcel()">
    <span *ngIf="exportingExpansionPlans">Exporting ...</span>
    <span *ngIf="!exportingExpansionPlans">Export to excel</span>
  </button>
</div>
<div *ngIf="viewToDisplay == 'expansionPlans'" class="mb-3 card card-body" style="overflow-x:scroll;">
<div>
  <table class="table table-borderless" id="excel-table" >
    <thead>
      <tr class="table-head">
        <th scope="col" sortable="account" (sort)="onSort($event)">Account</th>
        <th scope="col" sortable="country" (sort)="onSort($event)">Country</th>
        <th scope="col" sortable="local_company_name" (sort)="onSort($event)">Local Company Name</th>
        <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
        <th scope="col" sortable="startDate" (sort)="onSort($event)">Pooled since / Renewal Date</th>
        <th scope="col" sortable="benefits" (sort)="onSort($event)">Benefits</th>
        <th scope="col" sortable="employees" (sort)="onSort($event)">Employees</th>
        <th scope="col" sortable="premiumPoolCurrency" (sort)="onSort($event)">Premium in pool currency</th>
        <th scope="col" sortable="comments" (sort)="onSort($event)">Comments</th>
        <th scope="col" sortable="brokerComtact" (sort)="onSort($event)">Broker Contact</th>
        <th scope="col" sortable="companyContact" (sort)="onSort($event)">Company Contact</th>
        <th scope="col" sortable="insurer" (sort)="onSort($event)">Insurer</th>
        <th scope="col" sortable="insNetwork" (sort)="onSort($event)">Network</th>
      </tr>
    </thead>
      
    <p class="mt-3" *ngIf="expansionPlans?.items.length == 0 && !loadingExpansionPlans">There are no expansion plans.</p>

    <tbody *ngIf="expansionPlans?.items.length > 0">
      <tr *ngFor="let expansionPlan of expansionPlans?.items">
        <td data-label="Account">{{ expansionPlan.account.accountName }}</td>
        <td data-label="Country">{{ expansionPlan.country }}</td>
        <td data-label="Local Company Name">{{ expansionPlan.local_company_name }}</td>
        <td data-label="Status">{{ expansionPlan.status }}</td>
        <td data-label="Pooled since / Renewal Date">{{ expansionPlan.startDate  | date:'dd MMM yyyy' }}</td>
        <td data-label="Benefits">{{ expansionPlan.benefits }}</td>
        <td data-label="Employees">{{ expansionPlan.employees }}</td>
        <td data-label="Premium in pool currency">{{ expansionPlan.premiumPoolCurrency | number }}</td>
        <td data-label="Comments">{{ expansionPlan.comments }}</td>
        <td data-label="Broker Contact">{{ expansionPlan.brokerComtact }}</td>
        <td data-label="Company Contact">{{ expansionPlan.companyContact }}</td>
        <td data-label="Insurer">{{ expansionPlan.insurer }}</td>
        <td data-label="Network">{{ expansionPlan.insNetwork }}</td>
      </tr>
    </tbody>
  </table>
</div>
  <div class="d-flex justify-content-center mt-3" *ngIf="expansionPlans?.items.length > 0 && expansionPlans?.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{'disabled': !expansionPlans?.hasPreviousPage}">
        <a class="page-link" role="button" (click)="loadExpansionPlans(expansionPlansPageNumber - 1)">Previous</a>
      </li>
      <li class="page-item" [ngClass]="{'active': expansionPlansPageNumber == (i + 1)}" *ngFor="let item of counter(expansionPlans?.totalPages); let i = index">
        <a class="page-link" role="button" (click)="loadExpansionPlans(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [ngClass]="{'disabled': !expansionPlans?.hasNextPage}">
        <a class="page-link" role="button" (click)="loadExpansionPlans(expansionPlansPageNumber + 1)">Next</a>
      </li>
    </ul>
  </div>

</div>