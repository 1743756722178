<div *ngIf="(attachments?.length > 0 || commentAttachments?.length > 0 ||illustrationAttachments?.length >0 || adminDbAttachments?.length > 0)"
    class="mb-3 files-list">
    <h3 class="mb-2">
        {{ title }}
        <div *ngIf="downloadingFile" class="spinner-border spinner-border-sm ml-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </h3>
    <div class="mb-2" *ngFor="let file of attachments; let i = index" (click)="downloadFile(file)">
        <span>
        <a class="text-reset text-dark"> {{ this.documentService.sanitizeFileName(file?.name)}}</a>
        <span *ngIf="formatdate(file.fileDate)">
            at {{ formatdate(file.fileDate) }}
        </span>
        <fa-icon [icon]="faDownload" size="lg"></fa-icon>
    </span>
    </div>
    <div class="mb-2" *ngFor="let filePath of adminDbAttachments" (click)="downloadAdminDbFile(filePath)">
        <a class="text-reset text-dark">
            <span *ngIf="filePath.split('.')[1] == 'docx' ">{{documentService.sanitizeFileName(filePath,".pdf")}}</span>
            <span   *ngIf="filePath.split('.')[1] != 'docx' " > {{ this.documentService.sanitizeFileName(filePath)}}</span>
        </a>
        <fa-icon [icon]="faDownload" size="lg"></fa-icon>
    </div>
    <div class="mb-2" *ngFor="let file of commentAttachments; let i = index" (click)="downloadCommentFile(file)">
        <a class="text-reset text-dark">
            {{ this.documentService.sanitizeFileName(file?.name) }}
        </a>
        <span *ngIf="formatdate(file.fileDate)">
            at {{ formatdate(file.fileDate) }}
        </span>
        <fa-icon [icon]="faDownload" size="lg"></fa-icon>
    </div>
    <div class="mb-2" *ngFor="let filePath of illustrationAttachments " (click)="downloadFileFromPath(filePath)">
        <a class="text-reset text-dark">
            {{ this.documentService.sanitizeFileName(filePath)}}
        </a>
        <fa-icon [icon]="faDownload" size="lg"></fa-icon>
    </div>
</div>
<div *ngIf="(attachments?.length == 0 && commentAttachments?.length ==0 && illustrationAttachments?.length ==0 && adminDbAttachments?.length == 0)"
    class="mb-3 files-list">
    <p>No files for this task</p>
</div>