import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FileUploadTaskService } from 'src/app/services/file-upload-task/file-upload-task.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-illustration-input-detail-page',
  templateUrl: './illustration-input-detail-page.component.html',
  styleUrls: ['./illustration-input-detail-page.component.scss']
})
export class IllustrationInputDetailPageComponent implements OnInit {

  // hold the taskId
  taskId: string;
  networkMemberId: string;
  networkMemberName: string;
  clientName: string;
  status:TasksStatus;

  constructor(private titleService: Title, private activatedroute: ActivatedRoute, public fileUploadTaskService: FileUploadTaskService) { }

  // oninit method
  async ngOnInit() {
    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    let task = await this.fileUploadTaskService.getFileUploadTaskByIdAndSetActive(this.taskId);
    let networkMembers = task.networkMember;
    this.networkMemberId = networkMembers.id;
    this.networkMemberName = networkMembers.accountName;
    this.clientName = task.title;
    this.status = task.status.value;
    // set the title
    this.titleService.setTitle("Illustration input | Insurope");
  }
}
