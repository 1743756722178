<section class="filter-labelled">
  <label for="networkMemberInputId">Network Member</label>
  <div class="d-flex filter-box custom-filter">
    
    <!--<fa-icon [icon]="faFilter"></fa-icon>-->
    <div class="input-group">
      <input type="text" placeholder="Name" class="form-control" #searchField id="networkMemberInputId">
      <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="searchField.value">
        <span aria-hidden="true"></span>
      </button>
    </div>

    <ul *ngIf="!clickedOnNm">
      <li *ngFor="let nm of filteredNetworkMembers" (click)="selectNm(nm.id)">
        <p> {{nm.accountName }}</p>
      </li>
    </ul>
    <div *ngIf="searchingNetworkMembers" class="mt-2 searching-spinner">
      <div class="spinner-border spinner-border-sm mr-1" role="status">
      </div>
      <span>Searching...</span>
    </div>
  </div>
</section>

