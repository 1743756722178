import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';
import { faTh,faBars } from '@fortawesome/free-solid-svg-icons';
import { User } from 'oidc-client';
@Component({
  selector: 'app-approvals-list-page',
  templateUrl: './approvals-list-page.component.html',
  styleUrls: ['./approvals-list-page.component.scss']
})
export class ApprovalsListPageComponent implements OnInit {

  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isTableView:boolean=false;
  isCollaborator: boolean;
  statusFilter: number;
  headers:string[]=["Client","Network Member","Approval date","Due date","Status",]
  isTableViewStoredValueKey:string;
  faTh = faTh;
  faBars = faBars;
  
  

  // constructor
  constructor(private userService : UserService,
    private titleService: Title) { }

  // oninit method
  async ngOnInit() {
    // determine if the user is a collaborator
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Approvals | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
     
    this.changeIsTableViewValue(storedValue);
  }
  
  changeIsTableViewValue(value){
    if(value==null){
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    }else{
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}
