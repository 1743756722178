<section class="filter-labelled">
  <label *ngIf="inOverview" for="subscriberInputId">Subscriber</label>
  <div class="d-flex filter-box custom-filter">
 <!--<fa-icon [icon]="faFilter"></fa-icon>-->
    <div class="input-group" [formGroup]="inputGroup">
      <input type="text" placeholder="Add subscriber" class="form-control"formControlName="search" #searchField id="subscriberInputId">
      <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="searchField.value">
        <span aria-hidden="true"></span>
      </button>
    </div>
    <ul *ngIf="!clickedOnUser">
      <li *ngFor="let user of filteredUsers" (click)="selectUser(user.id)">
         <p> {{ user?.preferredEmail }}</p>
      </li>
    </ul>
    <div *ngIf="searchingUsers" class="mt-2 searching-spinner">
      <div class="spinner-border spinner-border-sm mr-1" role="status">
      </div>
      <span>Searching...</span>
    </div>
  </div>
</section>