import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IllustrationService } from 'src/app/services/illustration/illustration.service';
import { DOCUMENT, Location } from "@angular/common";
import { AccountDto, CountryDto, CustomFile } from 'src/app/web-api-client';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { UserService } from 'src/app/services/user.service';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { NetworkMembersService } from 'src/app/services/network-members/network-members.service';
import { Title } from '@angular/platform-browser';
import { MessageType, PopupNotificationsService } from 'src/app/services/popup-notifications/popup-notifications.service';

@Component({
  selector: 'app-request-illustration',
  templateUrl: './request-illustration.component.html',
  styleUrls: ['./request-illustration.component.scss']
})
export class RequestIllustrationComponent implements OnInit, OnDestroy {

  // hold list of clients
  filteredClients: AccountDto[] = [];

  // hold list of countries
  filteredCountries: CountryDto[] = [];

  // determine if list needs to be shown
  showClients: boolean = false;

  // hold the selected client
  selectedClient: AccountDto;

  // hold the uploaded files
  uploadedFiles: CustomFile[] = [];

  // hold the selected country
  selectedCountry: CountryDto;

  // boolean if it's a new client
  checkIsProspect: boolean = false;

  // search indicators
  searchingClient: boolean = false;
  searchingCountry: boolean = false;

  // new client boolean
  newClient: boolean = false;
  // sending request boolean
  sendingRequest: boolean = false;

  // show the warning message or not
  showWarningMessage: boolean = false;

  // take the search field as input
  @ViewChild('searchClientField', { static: true }) searchInputClientField: ElementRef;
  @ViewChild('searchCountryField', { static: true }) searchInputCountryField: ElementRef;

  // hold list of subscriptions
  subscriptions: Subscription[] = [];

  // declare the form
  illustrationForm = new UntypedFormGroup({
    ClientName: new UntypedFormControl('', [Validators.required]),
    AdditionalComment: new UntypedFormControl('', [Validators.required]),
    InsuropeTypeOfBusiness: new UntypedFormControl('', [Validators.required]),
    Country: new UntypedFormControl('', [Validators.required])
  });

  // constructor
  constructor(private accountsService: AccountsService,
    private countriesService: CountriesService,
    private illustrationService: IllustrationService,
    public location: Location,
    private router: Router,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private networkMemberService: NetworkMembersService,
    private titleService: Title,
    private popupNotificationsService: PopupNotificationsService) { }
    
    ngAfterViewInit() {

      // body onclick event
      this.document.getElementsByTagName('body')[0].onclick = (event) => {
        // if the target is not the search client input
        if (event.target != this.document.getElementById('searchClientName')) {
          this.filteredClients = [];
          this.newClient = true;
        }
        // if the target is not the search country input
        if (event.target != this.document.getElementById('searchCountryName')) {
          this.filteredCountries = [];
        } 
      };

  }

  // oninit method
  async ngOnInit(): Promise<void> {

    // set the title
    this.titleService.setTitle('Illustration Request | Insurope');

    // get the connected user networkmember id
    const networkMemberId = (await this.userService.getCurrentUser()).networkMemberIds;
    // get tht network member from the id
    const networkMember = await this.networkMemberService.getNetworkMemberById(networkMemberId[0]);
    // if the network memeber has only one country
    if (networkMember.countries.length == 1)
    {
      // disable the country field
      this.illustrationForm.controls.Country.disable();
      // set the selected country
      this.selectedCountry = networkMember.countries[0]
      // set the country name in the field
      this.illustrationForm.controls.Country.setValue(networkMember.countries[0].name);
    }
    
    this.subscriptions.push(fromEvent(this.searchInputClientField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 2
      filter(res => res.length > 2),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.fetchClient(text);
      this.selectedClient = null;
    }));

    this.subscriptions.push(fromEvent(this.searchInputCountryField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.fetchCountry(text);
    }));

  }

  // on destroy method
  ngOnDestroy() {
    // unsubscribe from subscriptions
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // when searching for a user
  async fetchClient(term: string) {
    if (term === ''){
      return this.filteredClients = [];
    }
    this.newClient = false;
    this.searchingClient = true;
    this.filteredClients = await this.accountsService.getPooledAndProspectAccountsByName(term.toLowerCase(), 0, 10);
    this.searchingClient = false;
  }

  // when searching for a country
  async fetchCountry(term: string) {
    if (term === '')
      return this.filteredCountries = [];
    this.searchingCountry = true;
    this.filteredCountries = this.countriesService.countries.filter(x => x.name.toLowerCase().includes(term.toLowerCase()));
    this.searchingCountry = false;
  }

  // called when selecting a client
  selectClient(client: AccountDto) {
    // hold the selected client
    this.selectedClient = client;
    // empty the list of clients
    this.filteredClients = [];
    if (client) {
      // set the client name
      this.searchInputClientField.nativeElement.value = client.accountName;
      this.illustrationForm.controls.ClientName.setValue(client.accountName);
      this.newClient = false;
    }
    else {
      this.newClient = true;
    }
  }
  
  // called when selecting a country
  selectCountry(country: CountryDto) {
    // hold the selected country
    this.selectedCountry = country;
    // empty the list of countries
    this.filteredCountries = [];
    // set the country name
    this.searchInputCountryField.nativeElement.value = country.name;
    this.illustrationForm.controls.Country.setValue(country.name);
  }

  // when the file is uploaded
  fileUploaded(response: {
    isUploaded: boolean;
    message: string;
    files?: File[];
  }) {
    // if files where uploaded
    if (response.isUploaded) {
      // loop through the files
      for (let i = 0; i < response.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(response.files[i]);
        reader.onload = () => {
          // create new file
          let file = new CustomFile();
          file.fileName = response.files[i].name;
          file.file = reader.result.toString().split("base64,")[1];
          // add the file to the list
          this.uploadedFiles.push(file);
        };
      }
    }
  }
  
  // method to remove a file
  removeFile(index: number) {
    // remove the file
    this.uploadedFiles.splice(index, 1);
  }
  
  // method to save the illustration
  save() {

    if (this.uploadedFiles.length == 0) {
      this.showWarningMessage = true;
    }
    else {
      this.showWarningMessage = false;
      this.createIllustration();
    }
    
  }

  createIllustration() {
    // hide message
    this.showWarningMessage = false;

    // start the sending
    this.sendingRequest = true;

    // take the data from the form
    let data = this.illustrationForm.getRawValue();
      
    // list of countries
    let countriesId: string[] = [this.selectedCountry.iso2];

    // create the illustration
    this.illustrationService.createIllustration(this.selectedClient?.id, data["ClientName"], data["AdditionalComment"], data["InsuropeTypeOfBusiness"], countriesId, this.uploadedFiles).then(response => {
      // if the response is successful
      if (response.status == 200) {
        // show the success message
        this.popupNotificationsService.showMessage("Illustration sent successfully!", MessageType.Success);
        // redirect to the dashboard
        this.router.navigate(['']);
      }
      else {
        console.error(response);
      }
      // stop the sending
      this.sendingRequest = false;
    });

    // clear the form
    this.illustrationForm.reset();
    this.uploadedFiles = [];
  }
  
}
