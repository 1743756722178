import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-finalized-accounts-list-page',
  templateUrl: './finalized-accounts-list-page.component.html',
  styleUrls: ['./finalized-accounts-list-page.component.scss']
})
export class FinalizedAccountsListPageComponent implements OnInit {
  isTableViewStoredValueKey: string;
  process = "annual-report"
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator: boolean;
  statusFilter: number;
  isTableView: boolean = false;
  headers: string[] = ["Client","Network Member","Status","Sent NM Date","Sent Client Date"]
  faTh = faTh;
  faBars = faBars;

  constructor(private userService: UserService, private titleService: Title) {
  }

  async ngOnInit() {
    // set the title
    this.titleService.setTitle("Annual Reports | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
    this.changeIsTableViewValue(storedValue);
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}
