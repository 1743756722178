import { Component, OnInit, Inject } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, AuthenticationResult, } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { apiConfig, b2cPolicies } from './b2c-config';
import { UserService } from './services/user.service';
import { UserDto } from './web-api-client';
import { UserAction } from './constants/user-actions';

interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string;
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  title = 'app';
  isIframe = false;
  loginDisplay = false;
  loadingAccount = true;
  notAuthenticated = false;
  termsAndConditionsAccepted = false;
  redirectUrl = "";

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public userService: UserService,
    public router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    localStorage.removeItem("networkMemberId");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationCancel) {
        if (!this.isIframe) {
          if (!localStorage.getItem("RedirectUrl")) {
            localStorage.setItem("RedirectUrl", event.url);
          }
        }
      }
    })

    this.isIframe = window !== window.parent && !window.opener;
    this.loadingAccount = true;
    //We need to request the token in silent to make sure if the user is logged in. This will trigger the login flow
    setTimeout(() => {
      if (!this.isIframe) {
        this.checkAndSetActiveAccount();
        if (this.authService.instance.getActiveAccount()) {
          this.authService.instance.acquireTokenSilent({ ...apiConfig, account: this.authService.instance.getActiveAccount() });
        }
        else
          this.authService.loginRedirect();
      }
    }, 1000
    );

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe(async (result: EventMessage) => {
        this.notAuthenticated = false;
        const payload = result.payload as AuthenticationResult;

        if (result.eventType == EventType.LOGIN_SUCCESS) {
          this.logUserAccess();
        }

        if (payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          window.alert('Profile has been updated successfully. \nPlease sign-in again.');
          return this.logout();
        }

        this.loadingAccount = false;

        this.authService.instance.setActiveAccount(payload.account);
        if (this.userService.isUnauthorized) {
          return;
        }

        var user = await this.userService.getCurrentUser();

        if (!user)
          return;

        if (user.termsAndConditions == null || !user.termsAndConditions.approved || user.privacyNotices == null || !user.privacyNotices.approved) {
          this.router.navigateByUrl("terms-and-conditions");
        }
        else {
          this.termsAndConditionsAccepted = true;
        }

        this.checkAndSetActiveAccount();

        if (localStorage.getItem("RedirectUrl")) {
          this.router.navigateByUrl(localStorage.getItem("RedirectUrl"));
          localStorage.removeItem("RedirectUrl");
        }
        this.loadingAccount = false;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE), takeUntil(this._destroying$))
      .subscribe(async (result: EventMessage) => {
        this.loadingAccount = false;
        this.notAuthenticated = true;

        // redirect to login page if user is not logged in
        if (!this.userService.isUnauthorized) {
          setTimeout(() => {
            this.authService.loginRedirect();
          }, 3000);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$))
      .subscribe(async () => {
        await this.setLoginDisplay();
      });

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
      },
      error: (error) => console.log(error)
    });
  }

  async logUserAccess() {
    var user = await this.userService.getCurrentUser();

    this.userService.logUserActivity(UserAction.Login, user.id, user.email, "");
  }

  async setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }

    let user: UserDto;
    this.userService.getCurrentUser().then(usr => user = usr);
  }

  editProfile() {
    const editProfileFlowRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.editProfile.authority,
    };
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
