import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanTaskClient, BooleanTaskDto, SortingElement } from 'src/app/web-api-client';
import { MessageType, PopupNotificationsService } from '../popup-notifications/popup-notifications.service';
import { FilterParameter } from 'src/app/models/FilterPrameter';

@Injectable({
  providedIn: 'root'
})
export class BooleanTaskService {

  // hold the active task
  activeBooleanTask: BooleanTaskDto;

  // constructor
  constructor(private booleanTaskClient: BooleanTaskClient, private router: Router, private notificationPopupService: PopupNotificationsService) { }

  // get the tasks per process
  async getMyOrganization(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<BooleanTaskDto[]> {
    // get the tasks by process
    return await this.booleanTaskClient.getMyOrganization(filterParameter.Id,
      filterParameter.SourceId,
      filterParameter.Title,
      filterParameter.SubTitle,
      filterParameter.DisplayDate,
      filterParameter.ProcessId,
      filterParameter.NetworkMemberId,
      filterParameter.ClientId,
      filterParameter.Country,
      filterParameter.Status,
      filterParameter.ClientName,
      filterParameter.NetworkMemberIds,
      filterParameter.SubscriberId,
      filterParameter.SearchDateLimit,
      filterParameter.OverdueDate,
      filterParameter.CreatedDate,
      sortingFields, page, pageSize).toPromise();
  }

  // get a task by Id
  async getBooleanTaskByIdAndSetActive(id: string): Promise<BooleanTaskDto> {
    try {
      // get the task
      this.activeBooleanTask = await this.booleanTaskClient.getBooleanTaskById(id).toPromise();
      // return the task
      return this.activeBooleanTask;
    }
    catch (error) {
      this.router.navigate(['/404'], { skipLocationChange: true });
    }
  }

  async getTasks(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<BooleanTaskDto[]> {
    return this.booleanTaskClient.getTasks(
      filterParameter.Id,
      filterParameter.SourceId,
      filterParameter.Title,
      filterParameter.SubTitle,
      filterParameter.DisplayDate,
      filterParameter.ProcessId,
      filterParameter.NetworkMemberId,
      filterParameter.ClientId,
      filterParameter.Country,
      filterParameter.Status,
      filterParameter.ClientName,
      filterParameter.NetworkMemberIds,
      filterParameter.SubscriberId,
      filterParameter.SearchDateLimit,
      filterParameter.OverdueDate,
      filterParameter.CreatedDate,
      sortingFields,
      page,
      pageSize).toPromise();
    //return this.booleanTaskClient.getTasks( ,sortingFields, page, pageSize).toPromise();
  }

  // method to change the status
  async changeTaskBooleanStatus(taskId: string, value: boolean): Promise<boolean> {
    try {
      // adapt the task's status
      await this.booleanTaskClient.update(taskId, value).toPromise();
      return true;
    }
    catch (error) {
      if (error.status == 404) {
        this.notificationPopupService.showMessage("The task you are trying to change does not exist.", MessageType.Error);
        return false
      }
    }
  }

  async downloadFile(taskId: string, fileId: string, systemAttachment: boolean) {
    return await this.booleanTaskClient.downloadFile(taskId, fileId).toPromise();
  }
  async getSubscribersEmails(taskId: string) {
    return this.booleanTaskClient.getTaskSubscribersByTaskId(taskId).toPromise();
  }
  async changeTaskStatus(id: string, status: number) {
    return await this.booleanTaskClient.changeTaskStatus(id, status).toPromise()
  }

  async downloadFileFromPath(taskId: string, filePath: string) {
    return await this.booleanTaskClient.downloadFileFromPath(taskId, filePath).toPromise();
  }

}
