import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformativeService } from 'src/app/services/informative.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-service-fees-detail-page',
  templateUrl: './service-fees-detail-page.component.html',
  styleUrls: ['./service-fees-detail-page.component.scss']
})
export class ServiceFeesDetailPageComponent implements OnInit {

  // hold the taskId
  taskId: string;
  networkMemberId: any;
  networkMemberName: string;
  clientName:string;
  status:TasksStatus;
  // constructor
  constructor(private activatedroute: ActivatedRoute, public informativeTaskService: InformativeService) { }

  // oninit method
  async ngOnInit() {

    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    let networkMembers = (await this.informativeTaskService.getInformative(this.taskId)).networkMember;
    this.networkMemberId = networkMembers.id;
    this.networkMemberName = networkMembers.accountName;
    this.clientName = (await this.informativeTaskService.getInformative(this.taskId)).title;
    this.status = (await this.informativeTaskService.getInformative(this.taskId)).status.value;
  }

}
