<section class="filter-labelled">
    <label for="selectStatusId">Status</label>
    <div class="d-flex filter-box custom-filter">
<select class="form-control" name="selectAction"  id="selectStatusId" 
[(ngModel)]='selected' (ngModelChange)="selectStatus($event)" >
       <option *ngFor="let op of options" [value]="op"  > 
    {{this.commonService.getTaskStatusByNumber(op)}}
       </option> 
 </select>
 </div>
</section>
