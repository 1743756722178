<div *ngIf="task">
  <section class="sub-header">
    <!-- Show the back btn -->
    <button class="btn btn-back" (click)="location.back()">Back</button>
    <!-- For the Account input/Approvals : If < overdue +3 working days -> Green
                                           if > overdue + 3 working days < 10 working days -> Orange
                                           if > overdue > working days -> Red
    {{ dateTime < displayDate.getDate() + 3 ? 'noOverdue' : dateTime > displayDate.getDate() + 3 &&  dateTime < displayDate.getDate() + 10 : 'midOverdue' : 'overdue' }}
    -->
    <h2
      class="{{ (dateTime.getDate() < task?.displayDate.getDate() + 3) ? '' : (dateTime.getDate() > task?.displayDate.getDate() + 3) &&  (dateTime.getDate() < task?.displayDate.getDate() + 10) ? 'midOverdue' : 'overdue' }}">
      {{ task?.title }}</h2>
      <span class="ms-auto"><p>{{commonService.getLastModifiedDate([task])}}</p></span>
  </section>

  <section class="card mb-3 upload-box">

    <div style="display: inline-block;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg"
      aria-controls="card-body" id="collapse-header">
      <strong *ngIf="task?.networkMember?.accountName && this.gfg" id="networkMemberCollapse">{{
        task?.networkMember?.accountName }}</strong><span class="ms-1" *ngIf=" this.gfg">(Click for more
        details...)</span>
      <strong *ngIf="!task?.networkMember?.accountName && this.gfg" class="badge badge-danger">Network Member
        unknown</strong>
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
      <div class="card-body">
        <div class="line-of-1"> 

          <div>
            <!-- Infos -->
            <div class="mb-3">
              <h2>{{ task?.title }}</h2>
              <span><strong>{{ task?.subTitle }}</strong></span>
            </div>

            <div class="mb-3">
              <!-- <span *ngIf="!task?.content">no content</span> -->
              <span *ngIf="task?.content">{{ task?.content }}</span>
            </div>

            <div class="mb-3">
              <h3>Network member</h3>
              <span *ngIf="task?.networkMember?.accountName" id="networkMember">{{ task?.networkMember?.accountName}}</span>
              <span *ngIf="!task?.networkMember?.accountName" class="badge badge-danger">Network Member unknown</span>
            </div>

            <div *ngIf="task.country" class="mb-3">
              <h3>Country</h3>
              <span>{{ task.country }}</span>
            </div>

            <div class="box mb-3 status-box">
              <div>
                <h3>Status</h3>
                <span class="status {{ statusText | lowercase }}">{{
                  statusText
                  }}</span>
              </div>
            </div>

            <!-- Files -->
            <app-attachment-list [attachments]="task?.attachments" [adminDbAttachments]="task?.adminDbAttachments" [commentAttachments]="commentAttachments"
              [service]="fileUploadTaskService"></app-attachment-list>

            <!--<app-attachment-list title="System attachments" [attachments]="task?.systemAttachments"
              [isSystemAttachment]="true" [service]="fileUploadTaskService"></app-attachment-list>-->

            <!-- Save button -->
            <button *ngIf="displayTaskAction" [disabled]="uploadedFiles.length == 0" class="btn btn-primary btn-action"
              (click)="save()">
              <span *ngIf="savingLoader" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="text-center" *ngIf="!task && loadingTask">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>