import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { faBars, faTh } from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "src/app/constants/user-roles";
import { IllustrationService } from "src/app/services/illustration/illustration.service";
import { NetworkMembersService } from "src/app/services/network-members/network-members.service";
import { UserService } from "src/app/services/user.service";
import {
  AccountDto,
  IllustrationRequestDto,
  IllustrationRevisionDto,
  TasksStatus,
} from "src/app/web-api-client";

@Component({
  selector: "app-illustration-list",
  templateUrl: "./illustration-list.component.html",
  styleUrls: ["./illustration-list.component.scss"],
})
export class IllustrationListComponent implements OnInit {

  // hold the list of the illustrations
  illustrations: IllustrationRequestDto[] = [];

  // if the user is a networkmember or not
  isNetworkMember: boolean;
  isTableView: boolean;
  // input for pagination
  @Input() page: number = 1;
  @Input() pageSize: number = 30;

  // determine if the filter needs to be shown
  @Input() displayFilter: boolean = true;
  @Input() onOverview: boolean = false;
  @Input() isOnClientView: boolean = false;
  @Input() networkmember: AccountDto = null;
  // loading indicator
  loadingIllustrations: boolean = false;
  hideShowMoreBtn: boolean = false;

  params: ParamMap;
  clientName: string;

  isTableViewStoredValueKey: string;
  faTh = faTh;
  faBars = faBars;
  // constructor
  constructor(
    public location: Location,
    private illustrationService: IllustrationService,
    private userService: UserService,
    private activatedroute: ActivatedRoute,
    private titleService: Title,
    private networkMembersService: NetworkMembersService
  ) { }

  // oninit method
  async ngOnInit(): Promise<void> {
    // determine if the user is a network member
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.titleService.setTitle("All Illustrations | Insurope");
    // subscribe to the route params
    this.activatedroute.queryParamMap.subscribe(async (params) => this.loadIllustrations(params));
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'

    if (this.isTableViewStoredValueKey) {
      let isTableView = localStorage.getItem(this.isTableViewStoredValueKey);
      var storedValue = JSON.parse(isTableView)
      if (isTableView) this.changeIsTableViewValue(storedValue);
      // set the title
    }
  }

  async loadMore() {
    if (!this.loadingIllustrations) {
      this.page++;
      await this.loadIllustrations(null, true);
    }

  }

  // method to get the illustrations
  async loadIllustrations(params?: any, loadMore?: boolean) {

    if (params) {
      this.params = params;
    }
    else {
      params = this.params;
    }

    let newItems;

    // loading started
    this.loadingIllustrations = true;

    if (params?.get("networkMemberId") && params.has("clientId")) {
      this.networkmember = await this.networkMembersService.getNetworkMemberById(params?.get("networkMemberId"));
    }
    var nmId = (params?.get("networkMemberId") ?? localStorage.getItem('networkMemberId'));
    var clientName = params?.get("clientName");
    var subscriberId = params?.get('subscriberId');
    var statusCode = localStorage.getItem('status');
    var status = TasksStatus[statusCode];

    if (subscriberId) {
      var subscriberEmail = (await this.userService.getUserById(subscriberId)).preferredEmail;
    }


    if (!loadMore) {
      this.illustrations = [];
      this.page = 1
    }

    if (this.isNetworkMember) {
      // load the illustrations
      newItems = await this.illustrationService.getMyOrganizationIllustrationsPaginated(
        clientName,
        status,
        subscriberId,
        subscriberEmail,
        null,
        null,
        this.page,
        this.pageSize
      );
    }
    else {
      if (!params.has('networkMemberId')) {
        // load the illustrations
        let networkMemberIDs: string[] = [];
        newItems = await this.illustrationService.getIllustrationsPaginated(
          networkMemberIDs,
          clientName,
          status,
          subscriberId,
          subscriberEmail,
          null,
          null,
          this.page,
          this.pageSize
        );
      }
      else {
        // load the illustrations
        let nmId = params?.get("networkMemberId") ? params?.get("networkMemberId") : localStorage.getItem('networkMemberId');
        let networkMemberIDs: string[] = [];
        if (!this.clientName && nmId) {
          networkMemberIDs.push(nmId);
        }
        newItems = await this.illustrationService.getIllustrationsPaginated(
          networkMemberIDs,
          clientName,
          status,
          subscriberId,
          subscriberEmail,
          null,
          null,
          this.page,
          this.pageSize
        );
      }
    }

    if (newItems.length < this.pageSize) {
      this.hideShowMoreBtn = true;
    } else {
      this.hideShowMoreBtn = false;
    }

    let updatedItems = newItems.map(illustration => {
      let updatedIllustration = illustration;

      // if (illustration.illustrationStage.name != "Finalized") {
      //   updatedIllustration.illustrationStage.name = "In-progress";
      // }

      return updatedIllustration;
    });

    this.illustrations = this.illustrations.concat(updatedItems);

    if (this.illustrations.length == 0) {
      document.getElementById("illustrations-view-btn")?.classList.add("d-none");
    }
    else {
      document.getElementById("illustrations-view-btn")?.classList.remove("d-none");
    }

    // loading finished
    this.loadingIllustrations = false;
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }

  replace(value: string, oldCharacter: string, newCharacter: string) {
    if (!value || !oldCharacter || !newCharacter) {
      return value;
    }

    return value.replace(oldCharacter, newCharacter);
  }
}
