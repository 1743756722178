import { Component, OnInit } from "@angular/core";
import { FaqService } from "src/app/services/faq.service";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FaqCategoryDto, QuestionDto } from "src/app/web-api-client";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { ModalBasicComponent } from "src/app/components/common/modal-basic/modal-basic.component";
import { UserService } from 'src/app/services/user.service';
import { UserRoles } from 'src/app/constants/user-roles';

@Component({
  selector: "app-faq-categories",
  templateUrl: "./faq-categories.component.html",
  styleUrls: ["./faq-categories.component.scss"],
})
export class FaqCategoriesComponent implements OnInit {
  faqCategories: FaqCategoryDto[] = [];
  questions: QuestionDto[] = [];
  activeCategory: number;
  newCategory: string;
  newQuestionTitle: string;
  newQuestionAnswer: string;
  selectCategory: FaqCategoryDto;

  // if the current user is a network member or not
  isContentEditor: boolean;

  // fontawesome
  faChevronDown = faChevronDown;

  // constructor
  constructor(private faqService: FaqService, private modalService: NgbModal, private userService: UserService) {}

  // OnInit
  async ngOnInit() {
    // permet d'afficher toutes les catégories
    // assigne à l'array faqCategories (initialisé plus haut) la méthode getCategories() définie dans le Service
    this.faqCategories = await this.faqService.getFaqCategories();

    // determine if the current user is a network member or not
    this.isContentEditor = await this.userService.isUserInRole(UserRoles.ContentEditor);    

    //display the list of questions
    this.questions = this.faqService.getQuestionsOfCategory(
      this.faqCategories[0]?.id
    );
  }

  // assigne à l'array faqs (initialisé plus haut) la liste des FAQs correspondant à l'id de la catégorie sélectionnée
  getCategoryID(category: FaqCategoryDto) {
    this.questions = this.faqService.getQuestionsOfCategory(category.id);
    this.selectCategory = category;
  }

  addFaqCategory() {
    this.faqService.addFaqCategory(this.newCategory);
    this.faqCategories = this.faqService.faqCategories;
    this.newCategory = "";
  }

  updateFaqCategory(updateCategory) {
    this.faqService.updateFaqCateogry(updateCategory);
  }

  // Pop up category
  openCat(category: FaqCategoryDto) {
    const modalRef = this.modalService.open(ModalBasicComponent);
    modalRef.componentInstance.category = category;
    modalRef.componentInstance.isQuestion = false;
    modalRef.result.then(() => {
      this.faqCategories = this.faqService.faqCategories;
    });
  }

  addQuestion() {
    this.faqService.addQuestion(this.selectCategory.id, this.newQuestionTitle, this.newQuestionAnswer);
    this.questions = this.faqService.questions;
    this.newQuestionTitle = "";
    this.newQuestionAnswer = "";
  }

  updateQuestion(updateQuestion) {
    this.faqService.updateQuestion(this.selectCategory.id, updateQuestion);
  }

  deleteQuestion(categoryId, deleteQuestion) {
    this.faqService.deleteQuestion(categoryId, deleteQuestion);
  }

  //Pop up question
  openQuestion(question: QuestionDto) {
    const modalRef = this.modalService.open(ModalBasicComponent);
    modalRef.componentInstance.category = this.selectCategory;
    modalRef.componentInstance.question = question;
    modalRef.componentInstance.isQuestion = true;
    modalRef.result.then(result => {
      if (result) {
        this.questions = this.questions.filter(c => c.id != question.id);
      }
    });
  }

  deleteQuest(questionId: string) {
    const modalRef = this.modalService.open(ModalBasicComponent);
    modalRef.componentInstance.questionId = questionId;
  }
}
