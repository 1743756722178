import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalQuestion } from 'src/app/models/approvalQuestion';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { BooleanTaskDto, TasksStatus } from 'src/app/web-api-client';
import * as XLSX from 'xlsx';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-approvals-detail-page',
  templateUrl: './approvals-detail-page.component.html',
  styleUrls: ['./approvals-detail-page.component.scss']
})
export class ApprovalsDetailPageComponent implements OnInit {

  // hold the task id
  taskId: string;
  task: BooleanTaskDto;
  // hold the list of approval questions
  approvalQuestions: ApprovalQuestion[] = [];
  clientName: string;
  // loading indicator
  loadingTask: boolean = false;
  country: string;
  gfg = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  networkMemberId: string;
  networkMemberName: string;
  status: TasksStatus;
  // constructor
  constructor(private activatedroute: ActivatedRoute,
    private booleanTaskService: BooleanTaskService,
    private titleService: Title,
    private router: Router,) { }

  // oninit method
  async ngOnInit() {

    // loading started
    this.loadingTask = true;

    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");

    // reset the active task
    this.booleanTaskService.activeBooleanTask = null;

    try {
      // get the task by id
      this.task = (await this.booleanTaskService.getBooleanTaskByIdAndSetActive(this.taskId));
      this.clientName = this.task?.title;
      let networkMembers = this.task.networkMember;
      this.networkMemberId = networkMembers.id;
      this.networkMemberName = networkMembers.accountName;
      this.status = this.task.status.value;
    }
    catch (error) {
      if (error.status === 404) {
        // if the illustration is not found, redirect to the 404 page
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }

    // loading finished
    this.loadingTask = false;

    // get the metadata of the active task
    this.getMetaData(this.booleanTaskService.activeBooleanTask, 1, 20);
  }

  getMetaData(task: BooleanTaskDto, page: number, pageSize: number) {
    // parse the metadata
    const parsed = JSON.parse(task.metaData);
    // save the approval questions
    this.approvalQuestions = parsed.ApprovalQuestions;
    this.approvalQuestions = this.approvalQuestions.sort((a, b) => a.ClientName.toLowerCase() <= b.ClientName.toLowerCase() ? -1 : 1);
    // paginate the approval questions
    this.approvalQuestions = this.approvalQuestions.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.booleanTaskService.activeBooleanTask.networkMember.accountName + ".xlsx");
  }
}
