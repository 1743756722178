export class OutstandingInputVersionDto {
    version: string;
    OutstandingInputs: OutstandingInput[];
}

export interface OutstandingInput {
    SubsidiaryName: string;
    MemberId: number;
    PoolingSystem: string;
    ClientName: string;
    ClientId: string;
    NetworkMember: string;
    XRReqCount: number;
    IsPrcolumn: boolean;
    IsXrcolumn: boolean;
    CountryName: string;
    DueDate: string;
    ReceiveDate: string;
    SendNMDate:string;
    SendClientDate:string; 
    ApprovalLetter:string;
}