
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, NgZone } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BooleanTaskDto, FileParameter, FileResponse } from 'src/app/web-api-client';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})

export class CommentDetailComponent implements OnInit {
  @ViewChild('commentForm') commentForm: NgForm;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('commentField', { static: true }) commentField: ElementRef;

  //Emit attachments and content of a comment
  @Output() commentEvent = new EventEmitter<{ content: string, attachments?: FileParameter[] }>();
  accountFile: FileParameter;
  showError = false;
  showAdminDbError = false;
  attachments: FileParameter[] = [];
  MAX_FILES_SIZE = 30000000;
  valid: boolean = false;
  text:string="";
  htmlContent = "";
  error: string;
  dragAreaClass: string;
  draggedFiles: any;
  _ngZone: NgZone;
  verifyText:string = ""
  templateForm: FormGroup;


  @Input() approval: BooleanTaskDto;
    constructor(private documentService: DocumentService) {
    this.templateForm = new FormGroup({
      textEditor: new FormControl(''),
    }); 
  }

  ngOnInit(): void {
    this.templateForm.valueChanges.subscribe(x=>{
      this.valid = this.isValid()
    })
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: File[] = event.dataTransfer.files;
      this.fileUploaded({ isUploaded: true, message : "", files: files });
    }
  }

  fileUploaded(response: { isUploaded: boolean, message: string, files?: File[] }) {
    var filesSize = 0;
    if (response.isUploaded) {
      // loop through the files
      for (let i = 0; i < response.files.length; i++) {
        // add the file to the list
        filesSize += response.files[i].size;
        
        const decodedFileName = decodeURIComponent(response.files[i].name);
        const fileNameParts = decodedFileName.split('.');
        const extension = fileNameParts.pop(); 
        const cleanedFileName = fileNameParts.join('.').replace(/[^a-zA-Z0-9]+/g, ' ');
  
        const newFileName = cleanedFileName + '.' + extension;
  
        this.attachments.push({ data: new Blob([response.files[i]]), fileName: newFileName });
        
        if (filesSize >= this.MAX_FILES_SIZE) {
          this.removeFile(this.attachments.length - 1);
          filesSize -= response.files[i].size;
          this.showFilesError();
        }
      }
      this.valid = this.isValid();
    } else {
      console.error(response.message);
    }
  }
  
  
  
  
  

  showFilesError() {
    this.showError = true;
    setTimeout(() => {
      this.showError = false
    }, 5000)
  }

  removeFile(index: number) {
    // remove the file
    this.attachments.splice(index, 1);
    this.valid=this.isValid();;
  }

  onClickSubmit() {
    this.commentEvent.emit({ content: this.templateForm.value.textEditor, attachments: this.attachments });
    this.attachments = [];
    this.templateForm.reset();
    this.valid = false;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  
  async getAccountFile(){
  try{
    var fileResponse =  await this.documentService.getAdminDbDocument(this.approval.adminDbAttachments[0], [this.approval.networkMemberId]);
    this.attachments.push({ data: new Blob([fileResponse.data]), fileName: fileResponse.fileName })
    this.valid=Boolean((this.attachments.length > 0 || this.text !="") || this.commentForm.valid);
  }catch(e){
    this.showAdminDbError = true;
    setTimeout(() => {
      this.showAdminDbError = false
    }, 5000)
  }
  
  }
  
  isValid(){
    return Boolean((this.attachments.length > 0 ||  !(this.templateForm.value.textEditor == null || this.templateForm.value.textEditor =="")) && this.templateForm.valid);
  }
}
