import { Component } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { UserRoles } from 'src/app/constants/user-roles';
import { environment } from 'src/environments/environment'
import { PowerBiReportDetails, ReportingClient } from 'src/app/web-api-client';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  w = window.innerWidth;
  isExpanded: boolean = false;
  isDisabled: boolean = true;
  // fontawesome
  faChevronDown = faChevronDown;
  faEye = faEye;
  faFile = faFile;
  faHandshake = faHandshake;
  faCalculator = faCalculator;
  faCreditCard = faCreditCard;
  faMugHot = faMugHot;
  faChartLine = faChartLine;
  faEnvelope = faEnvelope;
  faCircleQuestion = faCircleQuestion;
  isDevelopment: boolean = true;

  // if the current user is a network member or not
  isNetworkMember: boolean;
  // if the current user is a client or not
  isClient: boolean;
  isFinancial: boolean;
  reportsSubMenuItems: PowerBiReportDetails[]=null;

  toggleNav() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private userService: UserService, private reportingClient: ReportingClient) { }

  async ngOnInit() {
    this.isDisabled = this.w > 992 ? true : false;
    this.isExpanded = this.w > 992 ? true : false;

    // determine if the current user is a network member or not
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    // determine if the current user is a client or not
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);

    this.isFinancial = (await this.userService.getCurrentUser()).isFinancial;
    this.isDevelopment = environment.production == false
    this.createReportsSubMenu();
  }

  onResize(event) {
    this.w = window.innerWidth;
    this.isDisabled = this.w > 992 ? true : false;
    this.isExpanded = this.w > 992 ? true : false;
  }

  createReportsSubMenu() {
    this.reportingClient.getReportsName().subscribe(res=>{this.reportsSubMenuItems=res;});
  }
}
