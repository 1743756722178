<section class="sub-header pr-2">
    <button class="btn btn-back" (click)="location.back()">Back</button>
    <h2>{{ title }}</h2> <button *ngIf="onOverview" class="btn btn-primary btn-page align-self-end"
        [routerLink]="'/'+process"
        [queryParams]="isOnClientView ? {'networkMemberId': networkMemberId, 'clientId' : params?.get('clientId')}: null">
        View all
    </button>
    <span class="ms-auto">
        <p>{{lastRefreshDate}}</p>
    </span>
</section>

<app-multiple-filter-search *ngIf="displayFilter"></app-multiple-filter-search>

<div class="card-table" *ngIf="!tableView">
    <a *ngFor="let item of items" class="card" routerLink="/{{item?.processId}}/{{item?.id}}/{{this.clientIdParam}}">
        <div class="card-body">
            <h3
                class="{{ (dateTime.getDate() < item?.displayDate.getDate() + 3) ? '' : (dateTime.getDate() > item?.displayDate.getDate() + 3) &&  (dateTime.getDate() < item?.displayDate.getDate() + 10) ? 'midOverdue' : 'overdue' }}">
                {{item?.title}}</h3>
            <h4 *ngIf="!this.clientIdParam">{{item?.subTitle}}</h4>
            <h4 *ngIf="!isNetworkMember" class="network-member">{{item?.networkMember?.accountName}}</h4>
            <h4 *ngIf="!isNetworkMember && !item?.networkMemberId" class="network-member badge badge-danger">Network
                member unknown</h4>
        </div>
        <div class="card-footer">
            <p *ngIf="item.country">{{ item.country }}</p>
            <p class="status {{commonService.getTaskStatusByNumber(item?.status.value) | lowercase}}">
                {{commonService.getTaskStatusByNumber(item?.status.value)}}</p>
        </div>
    </a>
</div>
<!--Table view -->
<section *ngIf="tableView" class="card mt-3 mb-3 task-list">
    <div class="card-body">
        <table class="table table-borderless" id="excel-table">
            <thead>
                <tr class="table-head">
                    <th *ngFor="let head of headers">{{head}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items" routerLink="/{{item?.processId}}/{{item?.id}}/{{this.clientIdParam}}">
                    <td
                        class="{{ (dateTime.getDate() < item?.displayDate.getDate() + 3) ? '' : (dateTime.getDate() > item?.displayDate.getDate() + 3) &&  (dateTime.getDate() < item?.displayDate.getDate() + 10) ? 'midOverdue' : 'overdue' }}">
                        {{ item?.title }}</td>
                    <td *ngIf="!isNetworkMember">
                        <span *ngIf="!item?.networkMember?.accountName" class="network-member badge badge-danger">Network Member unknown</span>
                        <span *ngIf="item?.networkMember?.accountName">{{item?.networkMember?.accountName}}</span>
                    </td>
                    <td *ngIf="!this.clientIdParam && item?.subTitle">{{ item?.subTitle }}</td>
                 
                    <td *ngIf="item?.country && (process != 'account-input' || process != 'service-fees')" >{{item?.country}}</td>
                    <td><span class="status {{ commonService.getTaskStatusByNumber(item?.status.value) | lowercase }}">{{
                            commonService.getTaskStatusByNumber(item?.status.value) }}</span></td>
                    <td *ngIf="process =='annual-report' ">{{getSendNmDate(item?.metaData)}}</td>
                    <td *ngIf="process =='annual-report' ">{{getSendClientDate(item?.metaData)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</section>


<div *ngIf="items?.length == 0 && !loadingUploadTask">There are no outstanding tasks.</div>

<div class="d-flex justify-content-center">
    <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
        <span *ngIf="loadingUploadTask">Loading ...</span>
        <span *ngIf="!loadingUploadTask">Load more</span>
    </button>
</div>

<div *ngIf="loadingUploadTask && onOverview">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>