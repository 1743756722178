import { Injectable } from '@angular/core';
import { CountriesClient, CountryDto } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  // hold the countries
  countries: CountryDto[] = [];

  // constructor
  constructor(private countriesClient: CountriesClient) {

    // load the countries
    this.loadCountries();

  }

  // get the countries method
  async loadCountries() {
    // save the countries
    this.countries = await this.countriesClient.getCountries().toPromise();
  }
}
