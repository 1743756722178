import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';
import { GeneralTaskDto } from 'src/app/web-api-client';
import { faTh,faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-payments-list-page',
  templateUrl: './payments-list-page.component.html',
  styleUrls: ['./payments-list-page.component.scss']
})
export class PaymentsListPageComponent implements OnInit {
process = "payments"
items : GeneralTaskDto[]
@Input() page: number = 1;
@Input() pageSize: number = 5;
isCollaborator:boolean;
isTableView:boolean=false;
headers:string[]=["Client","Network Member","Due date","Status"]
isTableViewStoredValueKey:string;
statusFilter: number;
faTh = faTh;
faBars = faBars;
  constructor(private userService : UserService,
    private titleService: Title) { }

  async ngOnInit() {
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Payments | Insurope");
    this.isTableViewStoredValueKey=this.titleService.getTitle()+'_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
     
    this.changeIsTableViewValue(storedValue);
  }
  
    changeIsTableViewValue(value){
    if(value==null){
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    }else{
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}
