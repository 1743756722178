import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FileUploadTaskService } from 'src/app/services/file-upload-task/file-upload-task.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-pooling-permissions-detail-page',
  templateUrl: './pooling-permissions-detail-page.component.html',
  styleUrls: ['./pooling-permissions-detail-page.component.scss']
})
export class PoolingPermissionsDetailPageComponent implements OnInit {
  // hold the taskId
  taskId: string;
  networkMemberId: string;
  networkMemberName: string;
  clientName: string;
  status:TasksStatus;

  // constructor
  constructor(private titleService: Title, private activatedroute: ActivatedRoute, public fileUploadTaskService: FileUploadTaskService) { }

  async ngOnInit() {
    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    let task = await this.fileUploadTaskService.getFileUploadTaskByIdAndSetActive(this.taskId);this.status = task.status.value;
    let networkMember = task.networkMember;
    this.networkMemberId = networkMember.id;
    this.networkMemberName = networkMember.accountName;
    this.clientName = task.title;
    this.status = task.status.value;
    // set the title
    this.titleService.setTitle("Pooling Permission | Insurope");
  }
}
