import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';
import { FileUploadTaskDto } from 'src/app/web-api-client';
import { faTh,faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pooling-permissions-list-page',
  templateUrl: './pooling-permissions-list-page.component.html',
  styleUrls: ['./pooling-permissions-list-page.component.scss']
})
export class PoolingPermissionsListPageComponent implements OnInit {

  process = "pooling-permission"
  items : FileUploadTaskDto[]
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator:boolean;
  statusFilter: number;
  isTableView:boolean=false;
  headers:string[]=["Client","Network Member","Country","Status"]
  isTableViewStoredValueKey:string;
  faTh = faTh;
  faBars = faBars;
  constructor(private userService : UserService,
    private titleService: Title,
    private activatedroute: ActivatedRoute) { }
  
  async ngOnInit() {

    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Pooling Permissions | Insurope");
    this.isTableViewStoredValueKey=this.titleService.getTitle()+'_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
     
    this.changeIsTableViewValue(storedValue);
  }
  
    changeIsTableViewValue(value){
    if(value==null){
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    }else{
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
  

}
