import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DiscussionDto, FileParameter, TaskSubscribersDto, Comment, BooleanTaskDto, TasksStatus } from 'src/app/web-api-client';
import { faTrashCan, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { ProcessIds } from 'src/app/constants/processIds';
import { UserRoles } from 'src/app/constants/user-roles';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})

export class CommentListComponent implements OnInit {
  @Input() process: string;
  @Input() taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  @Input() status: TasksStatus;
  @Input() customSubscriberProvider: string = "";
  @Input() networkMemberId: string;
  @Input() networkMemberName: string;
  dateTime = new Date()
  @Input() clientName: string;
  @Input() country: string;
  @Input() illustrationRequestSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;
  @Input() service : any;
  approval : BooleanTaskDto;
  lastExportedDate: Date;
  userId: string;
  discussion: DiscussionDto;
  comments: Comment[] = [];
  commentId: number = 0;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  isNetworkMember: boolean;
  isClient: boolean;
  IsCollaborator: boolean;

  taskSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @ViewChild('discussionTable') discussionTable!: ElementRef;

  constructor(private commentService: CommentService, private userService: UserService, private activatedroute: ActivatedRoute, private booleanTaskService: BooleanTaskService) { }

  ngOnInit() {
    this.loadComments()
  }

  // Load comments from the service
  async loadComments() {
    this.userId = await this.userService.getCurrentUserId();
    if(this.process == "approval")
    this.approval = (await this.booleanTaskService.getBooleanTaskByIdAndSetActive(this.taskId));
    this.discussion = (await this.commentService.getDiscussionByTaskId(this.taskId));
    try {
      if (this.customSubscriberProvider.trim() !== "") {
        this.taskSubscribers = await this.commentService.getIllustrationTaskSubscriber(this.taskId,
          this.illustrationRequestSubscribers.clientSubscribers,
          this.illustrationRequestSubscribers.insuropeSubscribers,
          this.illustrationRequestSubscribers.networkMemberSubscribers
          );
      }
      else {
        this.taskSubscribers = await this.commentService.getTaskSubescribersByDiscussionId(this.taskId);
      }

      this.comments = this.commentService.comments;
      
    }
    catch (error) {
    
    }
  }

  // Add a comment at the top of the comments array
  async addComment(response: { content: string, attachments?: FileParameter[] }) {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);
    this.IsCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    var comment =  await this.commentService.addComment(this.taskId, response.content, response.attachments, this.customSubscriberProvider, this.process.replace('-', ''), this.clientName, this.networkMemberName,this.country).then();

    if(comment != undefined && !( this.process == ProcessIds.accountInputs || this.process == ProcessIds.annualReport || this.process == ProcessIds.illustrations)){
      if((this.isNetworkMember || this.isClient) && this.status ==  TasksStatus.Open)
      {
        await this.service.changeTaskStatus(this.taskId,TasksStatus.InProgress);
      } 
        
      if(this.IsCollaborator && this.status ==  TasksStatus.InProgress)
      {
        await this.service.changeTaskStatus(this.taskId,TasksStatus.Open);
      }    
    }  
  }

  // delete a comment
  async deleteComment(commentId: string) {
    await this.commentService.deleteComment(this.taskId, commentId);
  }

  // export the discussion
  async exportDiscussion() {
    let div = this.discussionTable.nativeElement;

    var img: any;
    var filename;
    var newImage: any;

    domtoimage.toPng(div, { bgcolor: '#eff4f8' })

      .then((dataUrl) => {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = () => {

          var pdfWidth = img.width + 20;
          var pdfHeight = img.height + 20;

          var doc;

          doc = new jsPDF(pdfWidth > pdfHeight ? 'l' : 'p', 'px', [pdfWidth, pdfHeight]);

          doc.addImage(newImage, 'PNG', 10, 10, img.width, img.height);

          filename = 'discussion-' + this.process + "-" + this.clientName + '.pdf';
          doc.save(filename);
        };
      })
      .catch(function (error) {
        // Error Handling
      });
    await this.commentService.editDiscussion(this.taskId, new Date());
    this.discussion.lastExported = new Date();
  }

  copySubscriberEmail(val: string) {
    navigator.clipboard.writeText(val);
  }

  getNetworkMemberId() {
    return this.networkMemberId;
  }

  async addSubscriber(id: string) {
    var sub = await this.commentService.addSubscriber(this.taskId, id);
    this.taskSubscribers = sub;
  }

  async deleteSubscriber(taskSubscribers: TaskSubscribersDto) {
    var subs = await this.commentService.deleteSubscriber(this.taskId, taskSubscribers.id);
    this.taskSubscribers = subs;
  }

  isCommentDeletable(comment: Comment) {

    var limitDate = comment.messageSent.getDate() + 1;
    var deleteComment = true;
    if (comment.userId == this.userId) {
      deleteComment = false;
      if(comment.attachments?.length > 0 && limitDate < this.dateTime.getDate()){
        deleteComment = true;
      }
    }
    return deleteComment;
  }

}
