<app-boolean-task-detail></app-boolean-task-detail>

<section class="card mb-3 approval-list" *ngIf="approvalQuestions.length > 0">
  <div class="card-body">
    <div style="display: inline-block; height: 100%;
    width: 100%;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="table">
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>
    <button type="button" class="btn btn-primary mb-3" style="display: inline; float:left;"
      (click)="exportexcel()">Export to excel</button>
    <table class="table table-borderless" id="excel-table" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
      <thead>
        <tr class="table-head">
          <th>Country</th>
          <th>Subsidiary Label</th>
          <th>Client name</th>
          <th>Approval Letter</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let approvalQuestion of approvalQuestions">
          <td data-label="Country">
            {{approvalQuestion.CountryName}}
          </td>
          <td data-label="Subsidiary Label">{{ approvalQuestion.SubsidiaryLabel }}</td>
          <td data-label="Client Name">{{ approvalQuestion.ClientName }}</td>
          <td data-label="Approval Letter">
            <p>{{ approvalQuestion.ExternalPoolComment }} </p><br>
            <p>{{ approvalQuestion.ApprovalLetter }} </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<!-- Show the comment component-->
<app-comment-list *ngIf="!loadingTask" process="approval" [taskId]="taskId" [networkMemberId]="networkMemberId"
  [networkMemberName]="task.networkMember?.accountName" [clientName]="this.task.title.substring(0, 15) + '...'+' '+approvalQuestions[0]?.PeriodEndDate?.split('-')[0]+' Approval' " [service]="booleanTaskService" [status]="status" [country]="this.approvalQuestions[0].CountryName"></app-comment-list>