import { Injectable } from '@angular/core';
import { DocumentsClient } from '../web-api-client';

@Injectable({
  providedIn: 'root'
})
export class DocumentService { 
  
  constructor(private documentClient : DocumentsClient) { }
  
  async getDocumentByPath(path:string){
    return await this.documentClient.getDocumentByPath(path).toPromise();
  }
  async getAdminDbDocument(path:string, networkMemberIds:string[]){
    return await this.documentClient.downloadFile(path, networkMemberIds).toPromise();
  }
  async geIllustrationtDocument(path:string){
    return await this.documentClient.getIllustrationFile(path).toPromise();
  }
  async getAdminDbDocToPdf(path:string){
    return await this.documentClient.getAdminDbDocumentInPdf(path).toPromise();
  }

  sanitizeFileName(path:string, fileType?:string){
    var pathSplited = path.split('/');
    var path = pathSplited[pathSplited.length-1];
    if(fileType){
      path =  path.split('.').slice(0, -1).join('.') + fileType
    }
    return path;
  }

}
