<!-- Show the comment form -->

<form [formGroup]="templateForm" (ngSubmit)="onClickSubmit()" class="m-3 pb-2" >

  <div draggable="true" ngClass="{{dragAreaClass}}"></div>
    
    <quill-editor id="quill-editor"  [styles]="{height: '300px',backgroundColor: '#ffffff' }" formControlName="textEditor"></quill-editor>
  
  
  <div class="d-flex cta-container">
    <app-file-upload-button (fileUploaded)="fileUploaded($event)" [acceptMultipleFiles]="true"></app-file-upload-button>
    <button [disabled]="!valid" class="btn btn-primary" type="submit">
      Send
    </button>
  
    <button type="button" style="margin-left: auto;" class="btn btn-primary" *ngIf="approval" (click)="getAccountFile()">
      Upload Account file
    </button>

  </div>
  <div class="d-flex flex-column ">
    <p *ngIf="!attachments">No files selected</p>
    <div id="files" *ngFor="let file of attachments; let i = index">
      {{ documentService.sanitizeFileName(file.fileName) }}
      <button type="button" class="btn-close align-middle" aria-label="Close" style="height: 2px; width: 2px;"
        (click)="removeFile(i)">
        <span aria-hidden="true"></span>
      </button>
    </div>
  </div>
  <p id="filesErrorMessage" *ngIf="showAdminDbError">No admin db file was found</p>
  <p id="filesErrorMessage" *ngIf="showError">You cannot add more than 30 MB of files. Please verify the size of your
  files</p>
</form>



