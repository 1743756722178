import { Injectable } from '@angular/core';
import { UserRoles } from 'src/app/constants/user-roles';
import { AssignedTo, HangfireJobClient } from 'src/app/web-api-client';
import { UserService } from '../user.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // constructor
  constructor(private userService: UserService, private datePipe: DatePipe, private hangfireJobExecutionService: HangfireJobClient) { }

  // get task status text from number
  getTaskStatusByNumber(n: number, isApproval : boolean = false) {
    
    switch (n) {
      case 0:
          return "Open";
      case 1:
        if(!isApproval){
          return "Closed";
        }else{
          return "Approved"
        }
        
      case 4:
        return "Deleted"
      case 5:
        return "Hidden"
      case 6:
        return "Finalized"
      case 7:
        return "In-progress"
      case null:
        return "All status"
      default:
        return "Open";
    }
  }

  async displayTaskAction(task: any): Promise<boolean> {
    let currentUser = await this.userService.getCurrentUser();
    let displayActions = true;
    switch (task.assignedTo) {
      case AssignedTo.NetworkMember:
        if (!(await this.userService.isUserInRole(UserRoles.NetworkMember) && currentUser.networkMemberIds.includes(task.networkMemberId)))
          displayActions = false;
        break;
      case AssignedTo.Internal:
        if (!await this.userService.isUserInRole(UserRoles.Collaborator))
          displayActions = false;
        break;
      case AssignedTo.Client:
        if (!(await this.userService.isUserInRole(UserRoles.Client) && currentUser.clientId == task.clientId))
          displayActions = false;
        break;
    }
    return displayActions;
  }
  getLastModifiedDate(items: any) {

    var lastModifiedDate = items.reduce((item, curr) => {
      return item.lastModified < curr.lastModified ? curr : item;
    }).lastModified;

    return "Last refresh : " + this.datePipe.transform(lastModifiedDate, "medium");
  }
  async getHangfireJobStartDateExecution(processName) {
    var lastModifiedDate = (await this.hangfireJobExecutionService.getJobInfo(processName).toPromise()).startDate;
    return "Last refresh : "+this.datePipe.transform(lastModifiedDate, "medium")
  }

}
