import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer  } from "@angular/platform-browser";
import { ActivatedRoute} from "@angular/router";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "src/app/constants/user-roles";
import { CommonService } from "src/app/services/common/common.service";
import { FileUploadTaskService } from "src/app/services/file-upload-task/file-upload-task.service";
import { UserService } from "src/app/services/user.service";
import { Attachment, AttachmentDTO, FileUploadTaskDto } from "src/app/web-api-client";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from "src/app/services/comment.service";

@Component({
  selector: "app-file-upload-task-detail",
  templateUrl: "./file-upload-task-detail.component.html",
  styleUrls: ["./file-upload-task-detail.component.scss"],
})
export class FileUploadTaskDetailComponent implements OnInit {
  dateTime = new Date()
  // hold the task id
  taskId: string;
  // hold the task
  task: FileUploadTaskDto;
  commentAttachments: AttachmentDTO[] = [];
  @Input() process: string;
  gfg = false;
  // hold the uploaded files
  uploadedFiles: File[] = [];
  statusText = "";
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  // fontawesome icons
  faTrash = faTrash;

  // check if the user is a network member or not
  isNetworkMember: boolean;

  // display the save loading indicator
  savingLoader = false;
  // loading indicator
  loadingTask: boolean = false;
  displayTaskAction: boolean = false;

  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    public fileUploadTaskService: FileUploadTaskService,
    private sanitizer: DomSanitizer,
    public location: Location,
    private userService: UserService,
    private commonService: CommonService,
    private commentService: CommentService
  ) { }

  // oninit method
  async ngOnInit() {
    // loading started
    this.loadingTask = true;
    // take the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get('id');
    // reset the active task
    this.fileUploadTaskService.activeFileUploadTask = null;
    // determine if the user is a network member
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    // get the task
    await this.getTask();
    // get the status text
    this.statusText = this.commonService.getTaskStatusByNumber(this.task.status.value);

    this.displayTaskAction = await this.commonService.displayTaskAction(this.task);

    // loading finished
    this.loadingTask = false;
  }

  // get the task
  async getTask() {
    // get the task by id
    this.task = await this.fileUploadTaskService.getFileUploadTaskByIdAndSetActive(this.taskId);
    await this.getCommentAttachments();
  }

  // when the file is uploaded
  fileUploaded(response: {
    isUploaded: boolean;
    message: string;
    files?: File[];
  }) {
    // if files where uploaded
    if (response.isUploaded) {
      // loop through the files
      for (let i = 0; i < response.files.length; i++) {
        // add the file to the list
        this.uploadedFiles.push(response.files[i]);
      }
    }
  }

  // method to remove a file
  removeFile(index: number) {
    // remove the file
    this.uploadedFiles.splice(index, 1);
  }

  // method to save the changes
  async save() {
    // saving started
    this.savingLoader = true;
    // loop through the files
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      const filePath = await this.fileUploadTaskService.uploadFileToTask(
        this.task,
        this.uploadedFiles[i],
        i === this.uploadedFiles.length - 1 ? true : false
      );
      const attachment = new Attachment();
      attachment.name = this.uploadedFiles[i].name;
      attachment.path = filePath;
      this.task.attachments.push(attachment);
    }
    // empty the selection
    this.uploadedFiles = [];
    this.ngOnInit();

    // saving finished
    this.savingLoader = false;
  }

  // sanitize the url to download the files
  sanitize(url: string) {
    // return safe url
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  async getCommentAttachments() {
    this.commentAttachments = await this.commentService.getAllAttachments(this.taskId);
  }
}
