import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from 'src/app/constants/user-roles';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-multiple-filter-search',
  templateUrl: './multiple-filter-search.component.html',
  styleUrls: ['./multiple-filter-search.component.scss']
})
export class MultipleFilterSearchComponent implements OnInit {


  filters: Array<boolean> = new Array<boolean>(10)
  title: string = "";
  printMessage : boolean ;
  @Input() isOnOverview:boolean;
  isNetworkMember : boolean;
  @Input()  isOnIllustration : boolean = false

  constructor(private activatedRoute : ActivatedRoute, private userService : UserService) { }

  async ngOnInit() {
    this.changeFilter(0);
    this.title = this.getTitle(0);
    this.activatedRoute.queryParamMap.subscribe(async (params) => { 
      if(params.get("networkMemberId") == null  && params.get("subscriberId") == null && params.get("clientId")==null){
        this.printMessage = true;
      }else{
        this.printMessage = false;
      }
    })
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);

  }

  changeFilter(index) {
    this.filters = new Array<boolean>(10);
    this.filters[index] = true;
    this.title = this.getTitle(index)
    this.printMessage = true;
  }

  getTitle(index) {
    switch (index) {
      case 0:
        return "network Member";
      case 1:
        return "subcriber";
      case 2:
        return "client";
      default:
        return "network Member";
    }
  }

}
