import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/constants/user-roles';
import { InformativeService } from 'src/app/services/informative.service';
import { UserService } from 'src/app/services/user.service';
import { GeneralTaskDto } from 'src/app/web-api-client';
import { faTh,faBars } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-service-fees-list-page',
  templateUrl: './service-fees-list-page.component.html',
  styleUrls: ['./service-fees-list-page.component.scss']
})
export class ServiceFeesListPageComponent implements OnInit {

  process="service-fees"
  items : GeneralTaskDto[]
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator:boolean;
  statusFilter: number;
  isTableView:boolean=false;
  isTableViewStoredValueKey:string
  faTh = faTh;
  faBars = faBars;
  headers:string[]=["Bill","Network Member","Due date","Status"]
  constructor(private informativeService:InformativeService, private userService : UserService, private titleService: Title) { }

  async ngOnInit(){
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Service Fees | Insurope");
    this.isTableViewStoredValueKey=this.titleService.getTitle()+'_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
     
    this.changeIsTableViewValue(storedValue);
  }
  
    changeIsTableViewValue(value){
    if(value==null){
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    }else{
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
  
}
