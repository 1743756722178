<div *ngIf="task">

  <section class="sub-header">
    <!-- Show the back btn -->
    <button class="btn btn-back" (click)="location.back()">Back</button>
     <!-- For the Account input/Approvals : If < overdue +3 working days -> Green
                                            if > overdue + 3 working days < 10 working days -> Orange
                                            if > overdue > working days -> Red
    -->
    <h2 class="{{  (dateTime.getDate() < task?.displayDate.getDate() + 3) ? '' : (dateTime.getDate() > task?.displayDate.getDate() + 3) &&  (dateTime.getDate() < task?.displayDate.getDate() + 10) ? 'midOverdue' : 'overdue' }}" >{{ task?.title }} {{ getMetaData(task) ? ' - ' + getMetaData(task) : '' }}</h2>
    <span class="ms-auto"><p>{{commonService.getLastModifiedDate([task])}}</p></span>
  </section>

 

  <section class="card mb-3 upload-box">

    <div style="display: inline-block;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="card-body" id="collapse-header">
      <strong *ngIf="task?.networkMember?.accountName && this.gfg" id="networkMemberCollapse">{{task?.networkMember?.accountName }}</strong><span class="ms-1" *ngIf=" this.gfg" >(Click for more details...)</span>
      <strong *ngIf="!task?.networkMember?.accountName && this.gfg" class="badge badge-danger">Network Member
        unknown</strong>
      <div class="rightElement"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>

    <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
      <div class="line-of-2">
        <!-- Infos -->
        <div class="info-section">
          <div class="mb-3">
            <h2>{{ task?.title }}</h2>
            <span><strong>{{ task?.subTitle }}</strong></span>
          </div>
    
          <div class="mb-3">
            <span *ngIf="!task?.content">no content</span>
            <span *ngIf="task?.content">{{ task?.content }}</span>
          </div>
    
          <div *ngIf="!isNetworkMember && task?.networkMember" class="mb-3">
            <h3>Network Member</h3>
            <span id="networkMember">{{ task.networkMember.accountName }}</span>
          </div>
        </div>
    
        <!--Box-->
        <div class="box-section">
          <div class="box mb-3 status-box">
            <div class="flex-container">
              <div class="flex-item">
                <h3>Status</h3>
                <span class="status {{ status | lowercase }}">{{ status }}</span>
              </div>
              <div class="flex-item">
                <h3>Approval Status</h3>
                <span *ngIf="task.value == true" class="{{ task.value ? 'text-success' : 'text-danger' }}">{{ "Approved" }}</span>
                <span *ngIf="task.value != true">No approval status for this task yet</span>
              </div>
            </div>
            <div class="flex-container">
              <div class="flex-item">
                <h3>Due date</h3>
                <span>{{ getDueDate(task) }}</span>
              </div>
              <div class="flex-item">
                <h3>Approval date</h3>
                <span>{{ getApprovalDate(task) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <!-- Files -->
      <div class="files-section">
        <app-attachment-list [illustrationAttachments]="task?.adminDbAttachments" [commentAttachments]="commentAttachments" [service]="booleanTaskService"></app-attachment-list>
      </div>
    </div>
    
    <div style="display: inline-block;">
      <div *ngIf="displayTaskAction && task.value != true" class="rightElement">
        <button  class="btn btn-success mr-3" (click)="approve()">
          Click to approve
        </button>
      </div>
    </div>
  </section>

</div>

<div class="text-center" *ngIf="!task && loadingTask">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
